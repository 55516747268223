import { render, staticRenderFns } from "./SServiceSchReport.vue?vue&type=template&id=4d225241&scoped=true"
import script from "./SServiceSchReport.vue?vue&type=script&lang=js"
export * from "./SServiceSchReport.vue?vue&type=script&lang=js"
import style0 from "./SServiceSchReport.vue?vue&type=style&index=0&id=4d225241&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d225241",
  null
  
)

export default component.exports